import { createGlobalStyle } from "styled-components";
import Capital from '../assets/fonts/05.woff2';

export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Capital';
    src: url('${Capital}') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    line-height: 1.2;
    scroll-behavior: smooth;
  }

  body {
    background: #F7F9FC;
  }

  h1, h2, p {
    margin: 0;
  }
`;