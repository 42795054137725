import React from 'react';
import styled from "styled-components";
import { ServiceContainer } from "../service/style";

export const FooterWrapper = styled.footer`
  background: white;
`;

export const FooterContainer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 40px 0;
`;

export const FooterFlex = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    font-size: 21px;
    letter-spacing: -1px;
    font-weight: 800;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  & > p {
    color: #696969;
    font-size: 13px;
    margin-top: 5px;
  }

  @media screen and (min-width: 1024px) {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;

    & > p {
      margin-top: 0;
    }
  }
`;

const Footer = (props) => (
  <FooterWrapper>
    <FooterContainer>
      <ServiceContainer>
        <FooterFlex>
          <h1>The Local Studio</h1>
          <p>&#169; 2023 The Local Studio</p>
        </FooterFlex>
      </ServiceContainer>
    </FooterContainer>
  </FooterWrapper>
);

export default Footer;