import styled from "styled-components";

export const ContactFlex = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 4rem;
  }
`;

export const ContactDetail = styled.div`
  @media screen and (min-width: 1024px) {
    width: 41.666667%;
    flex: 0 0 auto;
  }
`;

export const ContactFormDetail = styled.div`
  margin-top: 4rem;

  @media screen and (min-width: 1024px) {
    margin-top: 0;
    width: 100%;
  }
`;

export const ContactFooter = styled.div`
  margin-top: 2.5rem;

  & > h1 {
    font-size: 18px;
    text-decoration: underline;

    & > a {
      color: #333;
    font-family: 'Capital', sans-serif;
    }
  }

  @media screen and (min-width: 1024px) {

    & > h1 {
      font-size: 18px;
    }
  }
`;

export const ContactSocialMedia = styled.div`
  margin-top: 4rem;
  display: flex;
  gap: 1.25rem;
  align-items: center;

  @media screen and (min-width: 1024px) {
    & > a, & > a:visited {
      color: #1d1d1d;
    }
  }
`;

export const TextInput = styled.input`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 15px;
  width: 100%;
  background: transparent;
  outline: 0;
  font-size: 13px;
  margin-bottom: 1rem;
`;

export const FormSelect = styled.select`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 13px;
  padding-left: 15px;
  outline: none;
  width: 100%;
  background: #fff;
  margin-bottom: 1rem;

  & > option {
    font-size: 14px;
  }
`;

export const FormLabel = styled.label`
  font-size: 13px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const FormFlex = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 30px;
`;

export const TextAreaForm = styled.textarea`
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px 15px;
  background: transparent;
  width: 100%;
  margin: 30px 0;
  min-height: 180px;
  outline: 0;
  font-size: 13px;
`;

export const ButtonForm = styled.button`
  border: 1px solid #1d1d1d;
  color: #eee;
  padding: 15px 25px;
  border-radius: 10px;
  width: 100%;
  background-color: #222;
  cursor: pointer;
  font-size: 13px;

  &:hover {
    background: #1d1d1d;
    color: #fff;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 2rem;

  & > h1 {
    font-size: 16px;
    font-weight: 800;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-bottom: .8rem;
  }
`;

export const FormLabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;

  & > span {
    font-size: 12px;
    color: #aaa;
  }
`;