import React from 'react';
import ReactGA from 'react-ga4';
import img01 from '../../../assets/image/portfolio/001.webp';
import img02 from '../../../assets/image/portfolio/002.webp';
import img03 from '../../../assets/image/portfolio/003.webp';
import img04 from '../../../assets/image/portfolio/004.webp';
import img05 from '../../../assets/image/portfolio/005.webp';
import img07 from '../../../assets/image/portfolio/007.webp';
import img08 from '../../../assets/image/portfolio/fairview/edit-1.webp';
import { ServiceContainer, ServiceHeader, ServiceWrapper } from "../service/style";
import { PortfolioCard, PortfolioFooter, PortfolioFooterLeft, PortfolioOverlay, PortfolioThreeGrid, PortfolioTwoGrid } from "./style";

const Portfolio = (props) => (
    <ServiceWrapper id="projects">
        < ServiceContainer >
            <ServiceHeader>
                <h2>Portfolio</h2>
                <h1>Creating Stories <br />in Every Space</h1>
                <PortfolioTwoGrid>
                    <PortfolioCard data-aos="fade-right" onClick={() => {
                        ReactGA.event({
                            category: "Portfolio",
                            action: "Opennaire Clicked",
                            label: "Portfolio"
                        })
                        window.location = "/portfolio/openaire";
                    }}>
                        <PortfolioOverlay />
                        <img src={img01} alt="" />
                        <PortfolioFooter>
                            <PortfolioFooterLeft>
                                <h1>Openaire</h1>
                                <span>Semarang</span>
                            </PortfolioFooterLeft>
                            <span>&#169; 2021</span>
                        </PortfolioFooter>
                    </PortfolioCard>
                    <PortfolioCard data-aos="fade-left" >
                        <PortfolioOverlay />
                        <img src={img03} alt="" />
                        <PortfolioFooter>
                            <PortfolioFooterLeft>
                                <h1>RS House</h1>
                                <span>Lyndon Navapark, BSD</span>
                            </PortfolioFooterLeft>
                            <span>&#169; 2022</span>
                        </PortfolioFooter>
                    </PortfolioCard>
                </PortfolioTwoGrid>
                <PortfolioThreeGrid>
                    <PortfolioCard data-aos="fade-right">
                        <PortfolioOverlay />
                        <img src={img05} alt="" />
                        <PortfolioFooter>
                            <PortfolioFooterLeft>
                                <h1>BNPB Media Center</h1>
                                <span>Jakarta Timur</span>
                            </PortfolioFooterLeft>
                            <span>&#169; 2021</span>
                        </PortfolioFooter>
                    </PortfolioCard>
                    <PortfolioCard data-aos="fade-up">
                        <PortfolioOverlay />
                        <img src={img02} alt="" />
                        <PortfolioFooter>
                            <PortfolioFooterLeft>
                                <h1>SE House</h1>
                                <span>Sutera Olivia, Alam Sutera</span>
                            </PortfolioFooterLeft>
                            <span>&#169; 2023</span>
                        </PortfolioFooter>
                    </PortfolioCard>
                    <PortfolioCard data-aos="fade-left">
                        <PortfolioOverlay />
                        <img src={img04} alt="" />
                        <PortfolioFooter>
                            <PortfolioFooterLeft>
                                <h1>DC House</h1>
                                <span>Pulomas, Jakarta Timur</span>
                            </PortfolioFooterLeft>
                            <span>&#169; 2023</span>
                        </PortfolioFooter>
                    </PortfolioCard>
                </PortfolioThreeGrid>
                <PortfolioTwoGrid style={{ marginTop: "2.5rem" }}>
                    <PortfolioCard data-aos="fade-right" onClick={() => {
                        ReactGA.event({
                            category: "Portfolio",
                            action: "Fairview Clicked",
                            label: "Portfolio"
                        })
                        window.location = "/portfolio/fairview";
                    }}>
                        <PortfolioOverlay />
                        <img src={img08} alt="" />
                        <PortfolioFooter>
                            <PortfolioFooterLeft>
                                <h1>Fairview SOHO</h1>
                                <span>Karawaci, Tangerang</span>
                            </PortfolioFooterLeft>
                            <span>&#169; 2022</span>
                        </PortfolioFooter>
                    </PortfolioCard>
                    <PortfolioCard data-aos="fade-left">
                        <PortfolioOverlay />
                        <img src={img07} alt="" />
                        <PortfolioFooter>
                            <PortfolioFooterLeft>
                                <h1>Jakarta River City Office</h1>
                                <span>Jakarta</span>
                            </PortfolioFooterLeft>
                            <span>&#169; 2021</span>
                        </PortfolioFooter>
                    </PortfolioCard>
                </PortfolioTwoGrid>
            </ServiceHeader>
        </ServiceContainer >
    </ServiceWrapper >
);

export default Portfolio;