import styled from "styled-components";

export const HowAffiliateWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HowAffHeader = styled.div`
    & > h1 {
        font-size: 24px;
        font-family: 'Capital', sans-serif;
        text-align: center;
    }
`;

export const HowAffContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > img {
        width: 400px;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        gap: 2rem;
    }
`;

export const HowAffRight = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;

    & > p {
        font-size: 1.5rem;
        line-height: 1.5;
        margin-bottom: 1rem;
        text-align: center;
    }

    & > button {

        align-self: center;
    }

    @media screen and (min-width: 1024px) {
        width: 40%;

        & > p {
            font-size: 2rem;
            text-align: left;
        }

        & > button {
            align-self: flex-start;
        }
    }
`;