import styled from "styled-components";

export const CompanyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CompanyHeader = styled.div`
    & > h1 {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
    }
`;

export const CompanyList = styled.div`
    padding: 1rem 3rem;
    /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1); */
    display: grid;
    gap: 2rem;
    margin-top: 2rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-content: center;
    align-items: center;
    justify-items: center;

    & > img {
        width: 85px;
        filter: brightness(100%) contrast(0%) saturate(100%) blur(0px) hue-rotate(0deg);
        transition-timing-function: ease;
        transition-duration: .4s;

        &:hover{
            filter: none;
        }
    }

    @media screen and (min-width: 1024px) {
        gap: 4rem;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
`;