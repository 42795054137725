import React from "react";
import * as Hi from 'react-icons/hi2';
import img01 from '../../../assets/image/bnpb.webp';
import img02 from '../../../assets/image/portfolio/004.webp';
import { ServiceContainer, ServiceImg, ServiceWrapper, StepContainer, StepItem, StepItemFooter, StepItemHeader, StepRightContainer, StepRightHeader, StepWrapper } from "../service/style";
import { HookArrow, HookContainer, HookContent, HookContentText, HookHeader } from "./style";

export const HookSection = () => {
    return (
        <>
            <ServiceWrapper style={{ paddingTop: "2rem" }}>
                <ServiceContainer>
                    <HookContainer>
                        <HookHeader data-aos="fade-in">
                            <h1>Do you want a dream space that reflects your style and personality?</h1>
                        </HookHeader>
                        <HookContent>
                            <HookArrow data-aos="fade-right">
                                <Hi.HiArrowDown />
                            </HookArrow>
                            <HookContentText data-aos="fade-left">
                                <p>We are experts in creating inspiring, comfortable, and aesthetically pleasing spaces. With years of experience in the interior design industry, we are ready to help you make your dreams come true.</p>
                            </HookContentText>
                        </HookContent>
                    </HookContainer>
                </ServiceContainer>
            </ServiceWrapper>
            <ServiceWrapper background={"#fff"} style={{ paddingTop: '2rem' }} id="services">
                <ServiceContainer>
                    <StepWrapper>
                        <StepContainer>
                            <StepItem data-aos="fade-right">
                                <StepItemHeader>
                                    <ServiceImg>
                                        <img
                                            src="https://ui-themez.smartinnovates.net/items/geekfolio/light/assets/imgs/serv-icons/0.png"
                                            alt="" />
                                    </ServiceImg>
                                    <h1>Interior Design Consultancy</h1>
                                </StepItemHeader>
                                <StepItemFooter>
                                    <span>
                                        Consultancy
                                    </span>
                                    <p>Delivering uniquely crafted ideas through creative thinking
                                        to maximize your quality of life, by conducting the research, composing design and
                                        supervision.</p>
                                </StepItemFooter>
                            </StepItem>
                            <StepItem data-aos="fade-right">
                                <StepItemHeader>
                                    <ServiceImg>
                                        <img
                                            src="https://ui-themez.smartinnovates.net/items/geekfolio/light/assets/imgs/serv-icons/1.png"
                                            alt="" />
                                    </ServiceImg>
                                    <h1>Interior Design & Build</h1>
                                </StepItemHeader>
                                <StepItemFooter>
                                    <span>
                                        Design & Build
                                    </span>
                                    <p>Transforming your dreams into reality that mirrors your needs, taking account of
                                        your timeframe and budget as well as designing you a space by collaborating
                                        with our
                                        selected
                                        and trusted partners.</p>
                                </StepItemFooter>
                            </StepItem>
                            <StepItem data-aos="fade-right">
                                <StepItemHeader>
                                    <ServiceImg>
                                        <img
                                            src="https://ui-themez.smartinnovates.net/items/geekfolio/light/assets/imgs/serv-icons/2.png"
                                            alt="" />
                                    </ServiceImg>
                                    <h1>Interior Styling and Decor</h1>
                                </StepItemHeader>
                                <StepItemFooter>
                                    <span>
                                        Styling & Decoration
                                    </span>
                                    <p>Enhancing the aesthetics and functionality of a room or space; applying the
                                        finishes touches to a room or space that completes the desired look and feel, through
                                        furnishings, layouting and lighting.</p>
                                </StepItemFooter>
                            </StepItem>
                        </StepContainer>
                        <StepRightContainer data-aos="fade-left">
                            <StepRightHeader>
                                <h1>Our Provided Services</h1>
                                <p>Let's see how we are working. Discover the best interior design for your sweet home.</p>
                            </StepRightHeader>
                            <img src={img02} alt="bnpb" />
                            <img className="opennaire" src={img01} alt="openaire" />
                        </StepRightContainer>
                    </StepWrapper>
                </ServiceContainer>
            </ServiceWrapper>
        </>
    )
}
