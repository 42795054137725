import Lottie from 'lottie-react';
import React, { useState } from 'react';
import * as Gi from 'react-icons/gi';
import img from '../../../assets/image/partner.png';
import blink from '../../../assets/lottie/blink.json';
import {
    LetsTalkButton,
    MobileMenu,
    MobileMenuContainer,
    MobileMenuFlex,
    NavbarContainer,
    NavbarFlex,
    NavbarMenu,
    NavbarWrapper,
    NewMenuWrapper,
    TripleMenu
} from "./style";

const Navbar = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <NavbarWrapper>
            {/* <NavbarTop>
                <Marquee autoFill={true} speed={80} >
                    <span style={{ color: "#eee", fontSize: "11px", marginRight: "1rem", fontFamily: "'Capital', sans-serif" }}>Get Commission from Local Affiliate.   </span>
                </Marquee>
            </NavbarTop> */}
            <NavbarContainer>
                <NavbarFlex>
                    <h1>The Local Studio</h1>
                    <NavbarMenu>
                        <a href="#services">Services</a>
                        <a href="#projects">Projects</a>
                        <a href="#contacts">Contact</a>
                        <a href="affiliate"><NewMenuWrapper>
                            <Lottie animationData={blink} style={{ width: "70px" }} />
                        </NewMenuWrapper><div style={{ position: "absolute", top: "-2px" }}>
                                <Lottie animationData={blink} style={{ width: "70px" }} /></div><img src={img} alt="aff" /></a>
                    </NavbarMenu>
                    <LetsTalkButton
                        display={"none"}
                        onClick={() => window.open('https://wa.me/628118383313?text=Hi LocalStudio, I\'d like to know more on your services. Thanks')}>
                        Let's Talk
                    </LetsTalkButton>
                    <TripleMenu onClick={() => setIsOpen(!isOpen)}>
                        <Gi.GiHamburgerMenu color="#333" size={20} />
                    </TripleMenu>
                    <MobileMenu isOpen={isOpen}>
                        <MobileMenuContainer>
                            <MobileMenuFlex>
                                <a href="#services" onClick={() => setIsOpen(false)}>Services</a>
                                <a href="#projects" onClick={() => setIsOpen(false)}>Projects</a>
                                <a href="#contacts" onClick={() => setIsOpen(false)}>Contact</a>
                                <a href="affiliate"><img src={img} alt="aff" /></a>
                            </MobileMenuFlex>
                            <LetsTalkButton
                                display={"block"}
                                onClick={() => window.open('https://wa.me/628118383313?text=Hi LocalStudio, I\'d like to know more on your services. Thanks')}>
                                Let's Talk
                            </LetsTalkButton>
                        </MobileMenuContainer>
                    </MobileMenu>
                </NavbarFlex>
            </NavbarContainer>
        </NavbarWrapper>);
}

export default Navbar;