import React from "react";
import { ButtonForm, FormGroup, FormLabel, FormLabelGroup, FormSelect, TextInput } from "../../../section/landing/contact/style";
import { ModalContainer } from "../style";

export const ClientModal = () => {
    return (
        <ModalContainer>
            <h1>Refer Klien</h1>
            <form method='POST' action='https://formspree.io/f/xwkgbolp'>
                <FormGroup>
                    <h1>Referrer Detail</h1>
                    <FormLabel>
                        Your Name
                    </FormLabel>
                    <TextInput placeholder="Your Name" type="text" name="referrer" pattern=".{3,}" required title="Fill your name correctly" />
                    <FormLabel>
                        Your Phone Number
                    </FormLabel>
                    <TextInput placeholder="+62 / 0" type="text" name="referrer_phone" pattern=".{3,}" required title="Fill your phone correctly" />
                    <FormLabel>
                        Your Email
                    </FormLabel>
                    <TextInput placeholder="identity@group.com" type="text" name="referrer_email" pattern=".{3,}" required title="Fill your email correctly" />
                </FormGroup>
                <FormGroup style={{ marginBottom: "1rem" }}>
                    <h1>Client Detail</h1>
                    <FormLabel>
                        Client
                    </FormLabel>
                    <TextInput placeholder="Client" type="text" name="client_name" />
                    <FormLabelGroup>
                        <FormLabel>
                            Client Position
                        </FormLabel>
                        <span>Posisi klien terhadap project</span>
                    </FormLabelGroup>
                    <TextInput placeholder="e.g: Owner/Arsitek/Tukang/Kontraktor" type="text" name="client_position" />
                    <FormLabel>
                        Phone Number
                    </FormLabel>
                    <TextInput placeholder="+62 / 0" type="text" name="client_phone" />
                    <FormLabel>
                        Description (Optional)
                    </FormLabel>
                    <TextInput placeholder="e.g: Apartment Tipe Studio 15x10, Rumah LB 1 lantai 66m" type="text" name="description" />
                    <FormLabel>
                        Project
                    </FormLabel>
                    <FormSelect name="project">
                        <option value=""></option>
                        <hr />
                        <option value="Rumah Tinggal Pribadi">Rumah Tinggal Pribadi</option>
                        <option value="Apartement">Apartement</option>
                        <option value="Office">Office</option>
                        <option value="FnB">F&B</option>
                        <hr />
                        <option value="Retail">Retail</option>
                        <option value="Komersial">Komersial</option>
                    </FormSelect>
                    <FormLabel>
                        Job Type
                    </FormLabel>
                    <FormSelect name="type">
                        <option value=""></option>
                        <option value="Design & Build">Design & Build</option>
                        <option value="Design Consultacy">Design Consultacy (work with our trusted partner)</option>
                        <option value="Styling and Decorating">Styling and Decoration</option>
                        <option value="Build Only">Build Only (work with our trusted partner)</option>
                    </FormSelect>
                </FormGroup>
                <ButtonForm>
                    Submit
                </ButtonForm>
            </form>
        </ModalContainer>
    )
}