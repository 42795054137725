import React from "react";
import * as Bs from 'react-icons/bs';
import img from '../../../assets/image/portfolio/rshouse/002.webp';
import { IconWrapper, PortfolioContainer, PortfolioDetailWrapper, PortfolioHeaderDetail, PortfolioWrapper } from "../openaire/style";

export const RsHouse = () => {
    return (
        <PortfolioWrapper>
            <PortfolioContainer>
                <IconWrapper>
                    <Bs.BsArrowLeft size={20} onClick={() => window.location = "/"} />
                </IconWrapper>
                <PortfolioHeaderDetail>
                    <span>Type: Interior Design and Build</span>
                    <h1>The Local Studio</h1>
                    <span>Status: Completed (2023)</span>
                </PortfolioHeaderDetail>
                <PortfolioDetailWrapper>
                    <img src={img} alt="header" />
                </PortfolioDetailWrapper>
            </PortfolioContainer>
        </PortfolioWrapper>
    )
}