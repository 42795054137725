import styled from "styled-components";

export const PortfolioTwoGrid = styled.div`
  display: grid;
  gap: 2rem;
  margin-top: 5rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const PortfolioThreeGrid = styled.div`
  display: grid;
  gap: 2rem;
  margin-top: 2.5rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const PortfolioCard = styled.div`
  display: flex;
  flex-direction: column;
  transition: .5s ease;
  overflow: hidden;

  & > img {
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    z-index: -2;
  }

  &:hover {
    transform: scale(1.05) !important;
  }
`;

export const PortfolioFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;

  & > h1 {
    font-size: 20px;
    font-weight: 500;
  }

  & > span {
    color: #eee;
    font-weight: 300;
    font-size: 12px;
    font-family: 'Capital', sans-serif;
  }
`;

export const PortfolioFooterLeft = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 6px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #fff;
  }

  & > span {
    color: #eee;
    font-weight: 300;
    font-size: 13px;
  }
`;

export const PortfolioOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, rgb(0,0,0,.8) 90%);
  z-index: -1;
  border-radius: 8px;
`;