import styled from "styled-components";

export const ModalContainer = styled.div`
  & > h1 {
    font-size: 18px;
    margin-bottom: 2rem;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 700;
  }

  & > img {
    width: 100%;
  }
`;