import styled from "styled-components";

export const ContactWrapper = styled.div`
    padding: 6rem 8.25rem;
`;

export const ContactContainer = styled.div`
    text-align: center;
    padding: 4rem 0;
    display: flex;
    justify-content: center;

    & > p {
        font-size: 31px;
        max-width: 550px;
        line-height: 1.3;
    }
`;

export const ContactGrid = styled.div`
    display: grid;
    margin-top: 4rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

export const ContactCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
        color: #B9B4C7;
        text-transform: uppercase;
        margin-bottom: 4px;
    }

    & > h1 {
        font-weight: 400;
        font-size: 24px;
        color: #5C5470;
    }
`;