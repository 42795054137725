import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import logo from '../../assets/image/logo.png';
import Navbar from "../../section/landing/navbar";
import { PortFooterCompany, PortFooterContainer, PortFooterDetail, PortFooterDetailLeft, PortFooterDetailRight, PortFooterWrapper, PortfolioFooter } from "../portoflio/openaire/style";
import { ContactCard, ContactContainer, ContactGrid, ContactWrapper } from "./style";

export const ContactUs = () => {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Contact Us" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Navbar />
            <ContactWrapper>
                <ContactContainer>
                    <p>Let's get in touch!</p>
                </ContactContainer>
                <ContactGrid>
                    <ContactCard>
                        <span>Email</span>
                        <h1>thelocaldstudio@gmail.com</h1>
                    </ContactCard>
                    <ContactCard>
                        <span>Whatsapp</span>
                        <h1>08118383313</h1>
                    </ContactCard>
                    <ContactCard>
                        <span>Instagram</span>
                        <h1>@thelocaldstudio</h1>
                    </ContactCard>
                </ContactGrid>
            </ContactWrapper>
            <PortfolioFooter>
                <PortFooterContainer>
                    <PortFooterWrapper>
                        <PortFooterDetail>
                            <PortFooterDetailLeft>
                                <span>Office</span>
                            </PortFooterDetailLeft>
                            <PortFooterDetailRight>
                                <span>Taman Permata D14/8 <br />Lippo Karawaci, Tangerang, <br />Banten 15115</span>
                            </PortFooterDetailRight>
                        </PortFooterDetail>
                        <PortFooterDetail>
                            <PortFooterDetailLeft>
                                <span>Contact</span>
                            </PortFooterDetailLeft>
                            <PortFooterDetailRight>
                                <span>thelocaldstudio@gmail.com</span>
                            </PortFooterDetailRight>
                        </PortFooterDetail>
                    </PortFooterWrapper>
                    <span style={{ textAlign: "right", color: "#B4B4B3" }} onClick={() => window.open("https://instagram.com/thelocaldstudio")}>Instagram</span>
                </PortFooterContainer>
                <PortFooterCompany>
                    <img src={logo} alt="logo" />
                    <h1>The Local Studio</h1>
                </PortFooterCompany>
            </PortfolioFooter>
        </>
    );
}