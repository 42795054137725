import styled, { keyframes } from "styled-components";

export const NavbarWrapper = styled.nav`
  padding: 0 0 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 1024px) {
    padding: 0 0 12px;
  }
`;

export const NavbarContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 12px 10px 0;

  @media screen and (min-width: 1024px) {
    padding-top: 12px;
  }
`;

export const NavbarFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h1 {
    letter-spacing: -1px;
    font-size: 18px;
    font-weight: 800;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }
`;

export const NavbarMenu = styled.div`
  display: none;

  & > a {
    color: #222;
    font-size: 13px;
    transition: .3s;
    text-decoration: none;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans', sans-serif;
    position: relative;

    & > img {
      width: 80px;
    }
    &:hover {
      color: #323232;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
`;

export const NewMenuWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: -8px;
`;

export const LetsTalkButton = styled.button`
  background: #4a443a;
  color: #fff;
  padding: 11px 33px 11px 33px;
  border-radius: 16px;
  border: 0;
  outline: 0;
  transition: .3s;
  outline: none;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  display: ${({ display }) => display};
  font-family: 'Capital', sans-serif;

  &:hover {
    background: #323232;
    color: #fff;
    box-shadow: 2px 2px 0px 2px #0E0E0E;
  }

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const TripleMenu = styled.div`
  display: block;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  display: ${({ isOpen }) => isOpen ? "block" : "none"};
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  transition: all .3s ease;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const MobileMenuFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  & > a {
    color: #222;
    font-size: 15px;
    transition: .3s;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans', sans-serif;

    & > img {
      width: 80px;
    }

    &:hover {
      color: #323232;
    }
  }
`;

export const NavbarTop = styled.div`
  background-color: #010D09;
  height: 24px;
  padding: 0 10px;
  display: flex;
  align-items: center;
`;

const blinkAnimation = keyframes`
  0% {
          opacity: 0;
        }
        50% {
          opacity: .5;
        }
        100% {
          opacity: 1;
        }
`;

export const NavbarBlink = styled.div`
  & > span {
    font-family: 'Capital', sans-serif;
    color: #eee;
    font-size: 13px;
    animation: ${blinkAnimation} 6s infinite linear;;
  }
`;