import { useEffect } from "react";
import ReactGA from 'react-ga4';
import * as Bs from 'react-icons/bs';
import { useLocation } from "react-router-dom";
import img001 from '../../../assets/image/portfolio/fairview/edit-2.webp';
import img002 from '../../../assets/image/portfolio/fairview/edit-3.webp';
import img004 from '../../../assets/image/portfolio/fairview/edit-5.webp';
import img005 from '../../../assets/image/portfolio/fairview/edit-6.webp';
import img006 from '../../../assets/image/portfolio/fairview/edit-7.webp';
import img007 from '../../../assets/image/portfolio/fairview/edit-8.webp';
import { FooterPortfolio } from "../../../component/footer";
import { ButtonCTA, IconWrapper, PortfolioContainer, PortfolioDetailWrapper, PortfolioHeaderDetail, PortfolioImageGrid, PortfolioMessageContainer, PortfolioWrapper } from "../openaire/style";

export const FairviewPage = () => {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Opennaire" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <PortfolioWrapper>
            <PortfolioContainer>
                <IconWrapper>
                    <Bs.BsArrowLeft size={20} onClick={() => window.location = "/"} />
                </IconWrapper>
                <PortfolioHeaderDetail>
                    <span>Type: Apartement</span>
                    <h1>The Local Studio</h1>
                    <span>Status: Completed (2022)</span>
                </PortfolioHeaderDetail>
                <PortfolioDetailWrapper>
                    <img data-aos="fade-up" src={img001} alt="fairview" />
                    <p data-aos="fade-up" style={{ marginBottom: "1rem" }}>Introducing our interior design project at Fairview in SOHO Karawaci—a sophisticated blend of modern elegance and functional living. Our design ethos centers around creating harmonious spaces that reflect both style and comfort, tailored to meet the unique needs and preferences of our clients.</p>
                    <p data-aos="fade-up" style={{ marginTop: '1rem' }}>At Fairview, we have meticulously curated every element, from luxurious finishes to smart space utilization, ensuring a seamless integration of aesthetics and practicality. This project stands as a testament to our commitment to excellence and our passion for transforming living spaces into beautiful, inspiring environments.</p>
                    <PortfolioImageGrid data-aos="fade-up">
                        <img src={img004} alt="fairview" />
                        <img src={img005} alt="fairview" />
                        <img src={img006} alt="fairview" />
                        <img src={img007} alt="fairview" />
                        <img src={img002} alt="fairview" />
                    </PortfolioImageGrid>
                    <PortfolioMessageContainer>
                        <h1>Interested in collaborating with us?</h1>
                        <ButtonCTA onClick={() => window.location = "/contact"}>
                            Get Localed
                        </ButtonCTA>
                    </PortfolioMessageContainer>
                </PortfolioDetailWrapper>
            </PortfolioContainer>
            <FooterPortfolio />
        </PortfolioWrapper>
    )
}