import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import { CompanySection } from "../../section/landing/companies";
import Contact from "../../section/landing/contact";
import Footer from "../../section/landing/footer";
import Hero from "../../section/landing/hero";
import { HookSection } from "../../section/landing/hook";
import Navbar from "../../section/landing/navbar";
import Portfolio from "../../section/landing/portfolio";

const Main = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Homepage" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Navbar />
            <Hero />
            <CompanySection />
            <HookSection />
            <Portfolio />
            <Contact />
            <Footer />
        </>
    )
}

export default Main;