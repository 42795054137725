import React from 'react';
import * as Tb from 'react-icons/tb';
import { HeroContainer, HeroDetail, HeroMiniDetail, HeroMiniDetailFlex, HeroMiniFlex, HeroWrapper, Overlay } from "./style";

const Hero = (props) => (
    <HeroWrapper>
        <HeroContainer>
            <Overlay />
            <HeroDetail>
                {/* <h1>Hello 👋🏻 We're Thelocaldstudio.</h1> */}
                <h2>Create a Space that Tells Your Unique Story.</h2>
                <HeroMiniDetailFlex>
                    <HeroMiniFlex>
                        <HeroMiniDetail>
                            <Tb.TbCircleSquare color='#fff' />
                        </HeroMiniDetail>
                        <span>
                            Outstanding Customer Service
                        </span>
                    </HeroMiniFlex>
                    <HeroMiniFlex>
                        <HeroMiniDetail>
                            <Tb.TbPencil color='#fff' />
                        </HeroMiniDetail>
                        <span>
                            Personalized Design
                        </span>
                    </HeroMiniFlex>
                </HeroMiniDetailFlex>
                {/* <p>We appreciate your trust greatly our clients choose us & our products because they know we are the
                    best.</p> */}
            </HeroDetail>
        </HeroContainer>
    </HeroWrapper>
);

export default Hero;