import React, { useEffect } from "react";
import ReactGA from 'react-ga4';
import * as Bs from 'react-icons/bs';
import { useLocation } from "react-router-dom";
import Img001 from "../../../assets/image/page/portfolio/openaire/001.jpg";
import Img002 from '../../../assets/image/page/portfolio/openaire/002.jpg';
import Img003 from '../../../assets/image/page/portfolio/openaire/003.jpeg';
import Img004 from '../../../assets/image/page/portfolio/openaire/004.jpeg';
import Img005 from '../../../assets/image/page/portfolio/openaire/005.jpeg';
import { FooterPortfolio } from "../../../component/footer";
import { ButtonCTA, IconWrapper, PortfolioContainer, PortfolioDetailWrapper, PortfolioHeaderDetail, PortfolioImageGrid, PortfolioMessageContainer, PortfolioWrapper } from "./style";

export const Openaire = () => {
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Opennaire" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PortfolioWrapper>
            <PortfolioContainer>
                <IconWrapper>
                    <Bs.BsArrowLeft size={20} onClick={() => window.location = "/"} />
                </IconWrapper>
                <PortfolioHeaderDetail>
                    <span>Type: F&B and Retail</span>
                    <h1>The Local Studio</h1>
                    <span>Status: Completed (2021)</span>
                </PortfolioHeaderDetail>
                <PortfolioDetailWrapper>
                    <p data-aos="fade-right" style={{ marginTop: "1rem" }}>
                        Openaire is a one-stop destination located in Semarang that offers an open space that's able to provide tranquility, freshness, and friendliness through restaurant, bar, and market.
                    </p>
                    <img src={Img001} data-aos="fade-up" alt="img001" />
                    <p style={{ margin: "4rem 0" }} data-aos="fade-up">
                        Comes with a top-notch concept, it's surrounded with areas such as Market, Indoor & Outdoor Bar, Private Dining Area, VIP Rooms, Group Seating Area, and Outdoor Patio.
                    </p>
                    <PortfolioImageGrid>
                        <img src={Img002} data-aos="fade-right" alt="img002" />
                        <img src={Img003} data-aos="fade-left" alt="img003" />
                        <img src={Img004} data-aos="fade-right" alt="img004" />
                        <img src={Img005} data-aos="fade-left" alt="img005" />
                    </PortfolioImageGrid>
                    <PortfolioMessageContainer>
                        <h1>Interested in collaborating with us?</h1>
                        <ButtonCTA onClick={() => window.location = "/contact"}>
                            Get Localed
                        </ButtonCTA>
                    </PortfolioMessageContainer>
                </PortfolioDetailWrapper>
            </PortfolioContainer>
            <FooterPortfolio />
        </PortfolioWrapper>
    )
}