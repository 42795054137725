import styled from "styled-components";

export const ServiceWrapper = styled.section`
  padding: 5rem 0;
  background: ${({ background }) => background ? background : "#f1f3f5"};
  overflow: hidden;
`;

export const ServiceContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 1rem;

  @media screen and (min-width: 1024px) {
    padding: 0;
  }
`;

export const ServiceHeader = styled.div`
  display: flex;
  flex-direction: column;

  & > h2 {
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 8px 30px;
    align-self: flex-start;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px;
  }

  & > h1 {
    font-size: 44px;
    margin-top: 15px;
    font-weight: 800;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  & > p {
    color: #696969;
    font-size: 17px;
    margin-top: 1.5rem;
    line-height: 1.5;
  }

  @media screen and (min-width: 1024px) {
    & > h1 {
      font-size: 52px;
    }
  }
`;

export const ServiceGrid = styled.div`
  display: grid;
  gap: 2rem;
  margin-top: 4rem;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const ServiceCard = styled.div`
  padding: 60px 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  transition: .5s;
  background-color: #fff;

  &:hover {
    box-shadow: 8px 8px 0px 0px #222;
  transition: .5s ease;
  }
`;

export const ServiceImg = styled.div`
  width: 40px;

  & > img {
    width: 100%;
  }
`;

export const ServiceDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  & > h1 {
    font-size: 18px;
    margin-bottom: 21px;
    font-weight: 700;
    line-height: 1.5;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  & > p {
    color: #696969;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 300;
  }
`;

export const ServiceList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
`;

export const ServiceListWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > span {
    color: #696969;
    font-size: 12px;
    font-weight: 300;
  }
`;

export const LearnMoreWrapper = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 15px 0px 15px 30px;
  border-radius: 40px;
  outline: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  background: transparent;
  margin-top: 2rem;
  cursor: pointer;

  &:hover {
    background: white;
  }

  & > span {
    font-size: 12px;
    letter-spacing: .5px;
    font-weight: 300;
  }
`;

export const ArrowUp = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  position: absolute;
  right: 0;
  top: -1px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepWrapper = styled.div`
  display: flex;
  gap: 4rem;
  margin-top: 4rem;
  flex-direction: column-reverse;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const StepRightContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > img {
    margin-top: 2rem;
    border-radius: 8px;
  }

  @media screen and (min-width: 1024px) {
    width: 40%;

    & > .opennaire {
      height: 480px;
    }
  }
`;

export const StepRightHeader = styled.div`
   & > h1 {
    font-size: 48px;
    font-weight: 800;
    font-family: 'Plus Jakarta Sans', sans-serif;
   }

   & > p {
    color: #616161;
    font-size: 15px;
    margin-top: 2rem;
    line-height: 1.5;
   }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (min-width: 1024px) {
    width: 60%;
    padding-top: 6rem;
  }
`;

export const StepItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #d4d5d7;
  padding-bottom: 2rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    transition: .5s ease-in;
  }
`;

export const StepItemHeader = styled.div`
  margin-bottom: 4rem;

   & > h1 {
    font-size: 24px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-top: 1.5rem;
   }
`;

export const StepItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    font-size: 13px;
    color: #a7a7a6;
    text-transform: uppercase;
    font-weight: 500;
    flex: 1;
  }

  & > p {
    line-height: 1.5;
    color: #222;
    font-size: 15px;
    flex: 2;
  }
`;