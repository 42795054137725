import AOS from "aos";
import "aos/dist/aos.css";
import { Component } from "react";
import ReactGA from "react-ga4";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { Affiliate } from './pages/affiliate';
import { ContactUs } from './pages/contact';
import Main from './pages/main';
import { FairviewPage } from "./pages/portoflio/fariview";
import { Openaire } from './pages/portoflio/openaire';
import { RsHouse } from "./pages/portoflio/rshouse";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />
    },
    {
        path: "/portfolio/openaire",
        element: <Openaire />
    },
    {
        path: "/portfolio/fairview",
        element: <FairviewPage />
    },
    {
        path: "/portfolio/rshouse",
        element: <RsHouse />
    },
    {
        path: "/contact",
        element: <ContactUs />
    },
    {
        path: "/affiliate",
        element: <Affiliate />
    }
]);

class App extends Component {
    componentDidMount() {
        AOS.init({
            duration: 1000,
        });
    }

    render() {
        return (
            <RouterProvider router={router} />
        );
    }
}

export default App;
