import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import logo from '../../assets/image/partner.png';
import { ModalComponent } from "../../component/modal";
import { ClientModal } from "../../component/modal/client";
import { HowAffiliate } from "../../section/affiliate/how";
import Footer from "../../section/landing/footer";
import { Overlay } from "../../section/landing/hero/style";
import { AffiliateBanner, AffiliateBannerContent, AffiliateButton, AffiliateHero, AffiliateHeroFlex, AffiliateNavbar, AffiliateNavbarContainer } from "./style";

export const Affiliate = () => {
    const [show, setShow] = useState(false);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Partner" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <AffiliateNavbar>
                <AffiliateNavbarContainer>
                    <img src={logo} alt="affiliate" />
                </AffiliateNavbarContainer>
            </AffiliateNavbar>
            <AffiliateBanner>
                <Overlay />
                <AffiliateBannerContent>
                    <AffiliateHeroFlex>
                        <AffiliateHero>
                            <h1>Get Extra Income by Simply Joining Local Partner!</h1>
                            <p>Semudah Refer Klien dan kalau transaksi berhasil, kamu dapet 10% komisi.</p>
                            <AffiliateButton onClick={() => setShow(true)}>
                                Refer Klien
                            </AffiliateButton>
                        </AffiliateHero>
                        {/* <img src={people} alt="banner" /> */}
                    </AffiliateHeroFlex>
                </AffiliateBannerContent>
            </AffiliateBanner >
            <HowAffiliate />
            <Footer />
            <ModalComponent
                show={show}
                onHide={() => {
                    setShow(!show);
                }}
                body={<ClientModal />}
                size="m"
            />
        </>
    )
}