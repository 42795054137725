import styled from "styled-components";

export const HookContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HookHeader = styled.div`

    & > h1 {
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -2px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    }

    @media screen and (min-width: 1024px) {
        max-width: 55%;

        & > h1 {
            font-size: 68px;
        }
    }
`;

export const HookContent = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    flex-direction: column-reverse;
    gap: 2rem;

    @media screen and (min-width: 1024px) {
        gap: 0;
        align-items: center;
        margin-top: 4rem;
        flex-direction: row;
    }
`;

export const HookArrow = styled.div`
    border: 1px solid #222;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HookContentText = styled.div`
    & > p {
        font-size: 17px;
        font-weight: 300;
        line-height: 1.5;
    }

    @media screen and (min-width: 1024px) {
        max-width: 60%;
    }
`;