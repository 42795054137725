import React from "react";
import img01 from '../../../assets/company/bnpb.webp';
import img02 from '../../../assets/company/cc.webp';
import img03 from '../../../assets/company/jrc.svg';
import img05 from '../../../assets/company/opennair.webp';
import img06 from '../../../assets/company/springs.webp';
import img04 from '../../../assets/company/strada.webp';
import { ServiceContainer, ServiceWrapper } from "../service/style";
import { CompanyList } from "./style";

export const CompanySection = () => {
    return (
        <ServiceWrapper style={{ paddingTop: "2rem" }}>
            <ServiceContainer style={{ borderRadius: "8px" }}>
                {/* <CompanyHeader>
                    <h1>Trusted by Them</h1>
                </CompanyHeader> */}
                <CompanyList data-aos="fade-in">
                    <img src={img01} alt="bnpb" />
                    <img src={img02} alt="cc" />
                    <img src={img03} alt="jrc" />
                    <img src={img04} alt="strada" />
                    <img src={img05} alt="opennaire" />
                    <img src={img06} alt="springs" />
                </CompanyList>
            </ServiceContainer>
        </ServiceWrapper>
    )
}