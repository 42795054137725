import styled from "styled-components";
import img from '../../../assets/image/portfolio/fairview/edit-2.webp';

export const HeroWrapper = styled.section`
  background-image: url(${img});
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(.5);

  @media screen and (min-width: 1024px) {
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: -1;
`;

export const HeroContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  padding: 120px 0 40px;
  height: 100vh;

  @media screen and (min-width: 1024px) {
    padding: 4rem 0;
  }
`;

export const HeroDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 0 1rem;

  & > h1 {
    font-size: 16px;
    margin-bottom: 1rem;
    color: #fff;
  }

  & > h2 {
    font-size: 48px;
    color: #fff;
    margin-bottom: 1.25rem;
    font-weight: 600;
    letter-spacing: -1px;
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  & > p {
    color: #eee;
    line-height: 1.7;
    font-size: 17px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0;
    max-width: 75%;
    height: 100%;

    & > h1 {
      font-size: 16px;
    }

    & > h2 {
      font-size: 92px;
    }
  }
`;

export const HeroMiniDetailFlex = styled.div`
  display: flex;
  gap: 1rem;

  @media screen and (min-width: 1024px) {
    gap: 1.5rem;
  }
`;

export const HeroMiniFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  & > span {
    font-size: 13px;
    color: #fff;
  }
`;

export const HeroMiniDetail = styled.div`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #45423c;
  display: flex;
  align-items: center;
  justify-content: center;
`;