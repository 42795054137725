import styled from "styled-components";

export const PortfolioWrapper = styled.div`

`;

export const IconWrapper = styled.div`
    padding: 0 1rem;
    margin-bottom: 2rem;
    cursor: pointer;

    @media screen and (min-width: 1024px) {
        padding: 0 3rem;
    }
`;

export const PortfolioContainer = styled.div`
    padding: 1rem;

    @media screen and (min-width: 1024px) {
        padding: 3rem 6rem;
    }
`;

export const PortfolioHeaderDetail = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
    padding: 0 1rem;
    align-items: center;

    & > h1 {
        font-size: 18px;
        letter-spacing: -1px;
        font-weight: 700;
        text-align: center;
        font-family: 'Plus Jakarta Sans', sans-serif;
    }

    & > span {
        color: #7D7C7C;
        font-size: 13px;
    }

    @media screen and (min-width: 1024px) {
        padding: 0 3rem;
        flex-direction: row;
    }
`;

export const PortfolioDetailWrapper = styled.div`
    margin: 3rem 0;
    padding: 0 1rem;

    & > p {
        font-size: 16px;
        line-height: 1.5;
        margin: 5rem 0 4rem;
    }

    & > img {
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
    }


    @media screen and (min-width: 1024px) {
        padding: 0 3rem;

        & > p {
            font-size: 18px;
        }
    }
`;

export const PortfolioImageGrid = styled.div`
    display: grid;
    margin-top: 4rem;
    gap: 8px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    & > img {
        width: 100%;
        object-fit: contain;
        border-radius: 8px;
        transition: .3s ease;
        cursor: pointer;

        &:hover {
            transform: scale(1.02) !important;
        }
    }

    @media screen and (min-width: 1024px) {
        gap: 1rem;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        & > img {
            width: 100%;
        }
    }
`;

export const PortfolioFooter = styled.div`
    background: #4d4c44;
    padding: 1rem;



    @media screen and (min-width: 1024px) {
        padding: 4rem 9rem;
    }
`;

export const PortFooterContainer = styled.div`
    display: grid;

    & > span {
        font-size: 20px;
        color: #fff;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const PortFooterWrapper = styled.div``;

export const PortFooterDetail = styled.div`
    display: flex;
    color: #B4B4B3;
    margin-bottom: 2rem;
    justify-content: space-between;

    @media screen and (min-width: 1024px){
        justify-content: flex-start;
    }
`;

export const PortFooterDetailLeft = styled.div`
    @media screen and (min-width: 1024px){
        flex-basis: 33.33333%;
    }
`;

export const PortFooterDetailRight = styled.div`
    & > span {
        font-size: 16px;
        line-height: 1.4;
    }
    @media screen and (min-width: 1024px){
        flex-basis: 66.666667%;
    }
`

export const PortFooterCompany = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    color: #fff;
    align-items: center;

    & > img {
        height: 60px;
        width: 200px; 
        object-fit: cover;
    }

    & > h1 {
        font-size: 18px;
        letter-spacing: -1px;
        font-weight: 700;
        font-family: 'Plus Jakarta Sans', sans-serif;
    }
`;

export const PortfolioStatusWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const PortfolioStatus = styled.div`
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #333;
    color: #fff;
`;

export const PortfolioMessageContainer = styled.div`
    padding: 2rem 0rem;
    margin-top: 4rem;

    & > h1 {
        font-size: 30px;
        font-weight: 600;
        font-family: 'Plus Jakarta Sans', sans-serif;
    }
`;

export const ButtonCTA = styled.button`
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 12px 18px;
    outline: none;
    color: #333;
    margin-top: 1rem;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        box-shadow: 2px 2px 0px 2px #0E0E0E;
        transition: .4s ease;
    }
`;