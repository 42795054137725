import React from 'react';
import * as Fa from 'react-icons/fa';
import * as Tfi from 'react-icons/tfi';
import { ServiceContainer, ServiceHeader, ServiceWrapper } from "../service/style";
import {
    ButtonForm,
    ContactDetail,
    ContactFlex,
    ContactFooter,
    ContactFormDetail,
    ContactSocialMedia,
    FormFlex,
    TextAreaForm,
    TextInput
} from "./style";

const ContactMe = (props) => (
    <ServiceWrapper background="#fff" id="contacts">
        <ServiceContainer>
            <ContactFlex>
                <ContactDetail data-aos="fade-right">
                    <ServiceHeader>
                        <h2>Get in Touch</h2>
                        <h1>Did we catch your eyes?</h1>
                        <p> We love meeting new folks. If you would like to work with us or just want to get in touch, we are looking forward
                            to explore and experience various creative projects with you!</p>
                        <ContactFooter>
                            <h1><a href="mailto:monica.eliezer97@gmail.com">thelocaldstudio@gmail.com</a></h1>
                            <ContactSocialMedia>
                                <a href="https://wa.me/628118383313?text=Hei, I'd like to know more about your services"
                                    target="_blank" rel="noreferrer">
                                    <Fa.FaWhatsapp size={20} color="#1d1d1d" /></a>
                                <a href="https://instagram.com/thelocaldstudio" target="_blank" rel="noreferrer">
                                    <Fa.FaInstagram size={20} color="#1d1d1d" /></a>
                                <a href="mailto:thelocaldstudio@gmail.com"><Tfi.TfiEmail
                                    size={20} color="#1d1d1d" /></a>
                            </ContactSocialMedia>
                        </ContactFooter>
                    </ServiceHeader>
                </ContactDetail>
                <ContactFormDetail data-aos="fade-left">
                    <form method='POST' action='https://formspree.io/f/xayrjyaa'>
                        <FormFlex >
                            <TextInput style={{ marginBottom: "0" }} placeholder="Name" type="text" name="name" />
                            <TextInput style={{ marginBottom: "0" }} placeholder="Phone Number" type="text" name="phone" />
                        </FormFlex>
                        <TextInput style={{ marginBottom: "0" }} placeholder="Subject" type="text" name="subject" />
                        <TextAreaForm placeholder="Message" name="message" />
                        <ButtonForm>
                            Let's talk
                        </ButtonForm>
                    </form>
                </ContactFormDetail>
            </ContactFlex>
        </ServiceContainer>
    </ServiceWrapper>
);

export default ContactMe;