import React, { useState } from "react";
import img from '../../../assets/image/howaffiliate.webp';
import { ModalComponent } from "../../../component/modal";
import { ClientModal } from "../../../component/modal/client";
import { LetsTalkButton } from "../../landing/navbar/style";
import { ServiceContainer, ServiceWrapper } from "../../landing/service/style";
import { HowAffContent, HowAffHeader, HowAffRight, HowAffiliateWrapper } from "./style";

export const HowAffiliate = () => {
    const [show, setShow] = useState(false);

    return (
        <ServiceWrapper>
            <ServiceContainer>
                <HowAffiliateWrapper>
                    <HowAffHeader data-aos="fade-in">
                        <h1>Gimana cara Refer Klien?</h1>
                    </HowAffHeader>
                    <HowAffContent>
                        <img data-aos="fade-right" src={img} alt="phone" />
                        <HowAffRight data-aos="fade-left">
                            <p>Isi Form Klien dan kami yang akan follow up mereka!</p>
                            <LetsTalkButton onClick={() => setShow(true)}>
                                Refer Now
                            </LetsTalkButton>
                        </HowAffRight>
                    </HowAffContent>
                </HowAffiliateWrapper>
            </ServiceContainer>
            <ModalComponent
                show={show}
                onHide={() => {
                    setShow(!show);
                }}
                body={<ClientModal />}
                size="m"
            />
        </ServiceWrapper>
    )
}