import React from "react";
import logo from '../../assets/image/logo.png';
import { PortFooterCompany, PortFooterContainer, PortFooterDetail, PortFooterDetailLeft, PortFooterDetailRight, PortFooterWrapper, PortfolioFooter } from "../../pages/portoflio/openaire/style";

export const FooterPortfolio = () => {
    return (
        <PortfolioFooter>
            <PortFooterContainer>
                <PortFooterWrapper>
                    <PortFooterDetail>
                        <PortFooterDetailLeft>
                            <span>Office</span>
                        </PortFooterDetailLeft>
                        <PortFooterDetailRight>
                            <span>Taman Permata D14/8 <br />Lippo Karawaci, Tangerang, <br />Banten 15115</span>
                        </PortFooterDetailRight>
                    </PortFooterDetail>
                    <PortFooterDetail>
                        <PortFooterDetailLeft>
                            <span>Contact</span>
                        </PortFooterDetailLeft>
                        <PortFooterDetailRight>
                            <span>thelocaldstudio@gmail.com</span>
                        </PortFooterDetailRight>
                    </PortFooterDetail>
                </PortFooterWrapper>
                <span style={{ textAlign: "right", color: "#B4B4B3" }} onClick={() => window.open("https://instagram.com/thelocaldstudio")}>Instagram</span>
            </PortFooterContainer>
            <PortFooterCompany>
                <img src={logo} alt="logo" />
                <h1>The Local Studio</h1>
            </PortFooterCompany>
        </PortfolioFooter>
    )
}