import styled from "styled-components";
import img from '../../assets/image/affbanner2.webp';

export const AffiliateNavbar = styled.nav`
    position: fixed;
    background: white;
    padding: 4px 1rem;
    border-bottom: 1px solid #f0f3f7;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    @media screen and (min-width: 1024px) {
        padding: 4px 5rem;
    }
`;

export const AffiliateNavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;

    & > img {
        width: 105px;
    }
`;

export const AffiliateBanner = styled.div`
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    filter: grayscale(.5);
    height: 100vh;

    & > img {
        width: 200px;
    }
`;

export const AffiliateBannerContent = styled.div`
    max-width: 1024px;
    margin: 0 auto;
`;

export const AffiliateHeroFlex = styled.div`
    display: flex;

    & > img {
        margin-bottom: -2rem;
    }
`;

export const AffiliateHero = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 6rem 1rem 2rem;
    height: 100vh;

    & > h1 {
        font-size: 48px;
        color: #fff;
        font-family: 'Plus Jakarta Sans', sans-serif;
        line-height: 1.4;
        letter-spacing: -1px;
        font-weight: 800;
    }

    & > p {
        font-size: 17px;
        color: #eee;
        margin-top: 1.5rem;
        line-height: 1.5;
    }

    @media screen and (min-width: 1024px) {
        width: 62%;

        & > h1 {
            font-size: 69px;
        }

        & > p {
            font-size: 18px;
            width: 60%;
        }
    }
`;

export const AffiliateButton = styled.div`
    background-color: #222;
    height: 42px;
    padding: 0 32px;
    border-radius: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    align-self: flex-start;
    margin-top: 2rem;
    font-family: 'Capital', sans-serif;
    cursor: pointer;
    transition: .3s;

    &:hover {
        background: #323232;
        color: #fff;
        box-shadow: 2px 2px 0px 2px #0E0E0E;
    }
`;